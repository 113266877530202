import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
import { Button, Form, Input, Modal, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { productsAtom } from '../../../../../atoms/products';
import { containsNumber, getNumberFromString } from '../../../../../utils/numbers';
import { EditProductAddon } from './EditProductAddon';
import { ClassPrice, IAddon } from './Index';
import { ParentAddonsProps } from './Index';

export const EditModalAddons = ({
  onEdit,
  active,
  onCancel,
  pendingItem,
  onDeleteProduct,
  onDeleteAddon,
}: ParentAddonsProps) => {
  const [form] = Form.useForm();
  const [addedProducts, setAddedProducts] = useState([]) as any;
  const [activeProductAddon, setActiveProductAddon] = useState({ active: false, item: null });
  const products = useAtomValue(productsAtom);
  const [html, setHtml] = useState(pendingItem.description);
  const [inputValues, setInputValues] = useState([]) as any;

  useEffect(() => {
    form.setFieldsValue({ ...pendingItem });
    setAddedProducts(pendingItem.products);
  }, [pendingItem, form]);

  const Finish = (values: any) => {
    const data = {
      ...pendingItem,
      name: values.name,
      description: html,
      place: values.place,
      products: addedProducts,
    };
    onEdit(data);
    form.resetFields();
    setAddedProducts([]);
  };
  const onChange = () => {
    const tempArr: any[] = [];
    let checkedValues = products.data.filter(({ ID }) => inputValues.some((i: any) => +i === ID));
    checkedValues.forEach((value: any) => {
      const product = products.data.find(({ ID }) => ID === value.ID);
      if (!product) return;
      const productquantity = containsNumber(product.name.toString());
      const dataToAdd: any = {
        id: product.product_id,
        data_name: product.name,
        data_price: product.product_price,
        data_discount_price: product.product_price,
        quantity: !productquantity ? 1 : getNumberFromString(product.name),
      };
      tempArr.push(dataToAdd);
    });
    setAddedProducts([...tempArr]);
  };

  const onAddonProductChangeHandler = (addon: IAddon) => {
    let findIndex = addedProducts.findIndex((prod: IAddon) => prod.id === addon.id);
    let newAddonProducts = [...addedProducts];
    newAddonProducts[findIndex] = addon;
    setAddedProducts(newAddonProducts);
    setActiveProductAddon({ active: false, item: null });
  };

  const checkClass = () => {
    let matchCheck = html.match('remote-each-price');
    if (matchCheck?.length) {
      return true;
    } else {
      return false;
    }
  };

  const onPriceChange = () => {
    let newHtml;
    if (checkClass()) {
      newHtml = html.replace(ClassPrice.each, ClassPrice.full);
    } else {
      newHtml = html.replace(ClassPrice.full, ClassPrice.each);
    }
    setHtml(newHtml);
  };

  return (
    <Modal open={active} onOk={() => form.submit()} onCancel={onCancel} className="addonModal">
      <p
        className="removeProduct"
        onClick={() =>
          Modal.confirm({
            title: `Delete extra product`,
            content: 'Are you sure you want to delete this extra product?',
            onOk: () => onDeleteAddon(pendingItem),
          })
        }
      >
        <DeleteOutlined /> REMOVE
      </p>
      <p className="info_text">Bolded Info Text:</p>
      <Form form={form} onFinish={Finish}>
        <FormItem name="name">
          <Input placeholder="Info Text" />
        </FormItem>
        <FormItem name="place">
          <Input placeholder="Place in order" />
        </FormItem>

        {!!pendingItem.description && (
          <>
            <p className="info_text">Extra Product Description HTML:</p>
            <Editor
              options={{
                fontSize: 15,
                formatOnPaste: true,
                formatOnType: true,
                minimap: { enabled: false },
                lineNumbers: 'off',
                autoIndent: 'full',
                tabSize: 2,
                wordWrap: 'on',
              }}
              height="300px"
              language="html"
              theme="vs-dark"
              value={pendingItem.description}
              onChange={(e: any) => setHtml(e)}
              onMount={(editor) => setHtml(editor.getValue())}
            />
            <Button className="changer" type="primary" onClick={onPriceChange}>
              Switch to {checkClass() ? 'full' : 'each'} price
            </Button>
          </>
        )}
        <Select
          showSearch
          style={{ width: '100%', marginTop: 20 }}
          mode="multiple"
          placeholder="Select product"
          optionFilterProp="children"
          onChange={setInputValues}
          value={inputValues}
        >
          {Array.from(new Set(products.data.map((a: any) => a.product_id)))
            .map((id: any) => {
              return products.data.find((a: any) => a.product_id === id);
            })
            .map((prod) => {
              if (!prod) return null;
              return (
                <Select.Option key={prod.ID}>
                  ({prod.product_id}){prod.name}
                </Select.Option>
              );
            })}
        </Select>
        {inputValues.length > 0 && (
          <Button className="addButton" onClick={onChange}>
            Add All
          </Button>
        )}
      </Form>
      <p className="addonsP">Products Added:</p>
      <div className="addon_products">
        {addedProducts.length > 0 ? (
          addedProducts.map((added: any) => (
            <div key={added.id}>
              <span style={{ fontSize: 13 }} onClick={() => setActiveProductAddon({ active: true, item: added })}>
                {added.id} <EditOutlined style={{ color: 'blue' }} />
              </span>
              <CloseOutlined onClick={() => onDeleteProduct(added.id, setAddedProducts)} className="removeAddon" />
            </div>
          ))
        ) : (
          <span>No products added</span>
        )}
        <EditProductAddon
          onCancel={() => setActiveProductAddon({ active: false, item: null })}
          activeProductAddon={activeProductAddon}
          onAddonProductChangeHandler={(addon: any) => onAddonProductChangeHandler(addon)}
        />
      </div>
    </Modal>
  );
};
